export class TelephoneInput {

	/**
	 * Set the core values for our config
	 */
	static getConfig() {
		return {
			preferredCountries: ['gb', 'ie'],
			utilsScript: "/assets/libraries/intl-tel-input/js/utils.js"
		};
	}

	/**
	 * Initialise telephone and mobile inputs
	 */
	static init() {
		let initialCountry, countryCode, options;

		let $telephone = $('input[name=telephone]');
		let $mobile = $('input[name=mobile]');

		// Do we have a default country code stored in the form?
		let defaultCode = countryCode = $('input[name=countryCode]').length ? $('input[name=countryCode]').val() : 'gb';

		// Set countryCode from the country dropdown if a value is selected, but default to defaultCode
		if ($('select[name=country]').length) {
			countryCode = $('select[name=country]').length && $('select[name=country]').val().length
				? $('select[name=country]').find('option:selected').data('code')
				: defaultCode;
		}

		let preferredCountries = [...new Set([defaultCode, countryCode])]; // array_unique

		if ($telephone.length) {
			options = {
				hiddenInput: "telephone_full",
				preferredCountries: preferredCountries,
				placeholderNumberType: "FIXED_LINE"
			};
			if (($telephone.val().length == 0) || (false === /^\+/.test($telephone.val()))) {
				options.initialCountry = countryCode;
			}
			$telephone.intlTelInput($.extend({}, TelephoneInput.getConfig(), options));
		}

		if ($mobile.length) {
			options = {
				hiddenInput: "mobile_full",
				preferredCountries: preferredCountries,
			};
			if (($mobile.val().length == 0) || (false === /^\+/.test($mobile.val()))) {
				options.initialCountry = countryCode;
			}
			$mobile.intlTelInput($.extend({}, TelephoneInput.getConfig(), options));
		}

		$(document)
			.on('invalid.zf.abide', function (e, $el) {
				let name = $el.attr('name');
				if (name == 'telephone' || name == 'mobile') {
					$el.closest('div.iti').next('span.form-error').show();
				}
			})
			.on('valid.zf.abide', function (e, $el) {
				let name = $el.attr('name');
				if (name == 'telephone' || name == 'mobile') {
					$el.closest('div.iti').next('span.form-error').hide();
				}
			});
	}

	/**
	 * Format a telephone number
	 */
	static format(value, format = 2) {
		let data = value.match(/\+\d+/);
		if (data == null) {
			return value;
		}
		value = data[0];
		return window.intlTelInputUtils.formatNumber(value, null, format);
	}

	/**
	 * Loads the utils
	 */
	static loadUtils(callback) {
		let prom = window.intlTelInputGlobals.loadUtils("/assets/libraries/intl-tel-input/js/utils.js");
		if (prom == null) {
			return false;
		} else {
			prom.then(callback);
			return true;
		}
	}
}

// window.intlTelInputGlobals.loadUtils("/assets/libraries/intl-tel-input/js/utils.js");
