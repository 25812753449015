export class Toaster
{
    message;
    variant;
    icon;
    duration;

    static get DEFAULT_DURATION() { return 5000; }

    constructor(message, variant = 'primary', icon = 'info-circle', duration = Toaster.DEFAULT_DURATION)
    {
        this.message = message;
        this.variant = variant;
        this.icon = icon;
        this.duration = duration;
    }

    toast()
    {
        const message = this.message;
        const variant = this.variant;
        const icon = this.icon;
        const duration = this.duration;

        const alert = Object.assign(
            document.createElement('sl-alert'),
            {
                variant,
                closable: true,
                duration: duration,
                innerHTML: `<sl-icon name="${icon}" slot="icon"></sl-icon>${message}`
            }
        );

        document.body.append(alert);
        return alert.toast();
    }

    static create(message, title, variant, icon, duration)
    {
        if (title != null) {
            message = `<strong>${title}</strong><br>${message}`;
        }
        if (duration == null) {
            duration = Toaster.DEFAULT_DURATION;
        }
        const toast = new Toaster(message, variant, icon, duration);
        return toast.toast();
    }

    static primary(message, title = null, duration = null)
    {
        return Toaster.create(message, title, 'primary', 'info-circle', duration);
    }

    static success(message, title = null, duration = null)
    {
        return Toaster.create(message, title, 'success', 'check2-circle', duration);
    }

    static neutral(message, title = null, duration = null)
    {
        return Toaster.create(message, title, 'neutral', 'gear', duration);
    }

    static warning(message, title = null, duration = null)
    {
        return Toaster.create(message, title, 'warning', 'exclamation-triangle', duration);
    }

    static danger(message, title = null, duration = Infinity)
    {
        return Toaster.create(message, title, 'danger', 'exclamation-octagon', duration);
    }

    static error(message, title = null, duration = Infinity)
    {
        return Toaster.danger(message, title, duration);
    }
}
