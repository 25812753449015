[window.PRODUCTION, window.DEVELOPMENT, window.STAGING] = [false, false, false];
if ($('html').data('environment')) {
    if ('development' == $('html').data('environment')) {
        window.DEVELOPMENT = true;
    } else {
        window.STAGING = true;
    }
} else {
    window.PRODUCTION = true;
}
