import i18n from './i18n.js';

if (typeof Dropzone != 'undefined') {
	Dropzone.options.uploadFile = false;
}

export function createImportDropzone(config = {}) {
	if (typeof Dropzone != 'undefined') {

		var acceptedFiles = $('#upload-file').data('accepted-files');
		if (acceptedFiles == undefined) {
			acceptedFiles = null;
		}

		var maxFileSize = $('#upload-file').data('max-filesize');
		if (maxFileSize == undefined) {
			maxFileSize = 5;
		}

		const options = $.extend(
			true,
			{
				acceptedFiles: acceptedFiles,
				paramName: 'file',
				maxFiles: 1,
				maxFilesize: maxFileSize,
				timeout: 180000,
				previewTemplate: document.querySelector('#dztpl').innerHTML,
				resizeHeight: 100,
				resizeMethod: 'contain',
				sending: function (file, xhr, formData) {
					$('#upload-error').addClass('hide');
					$('#import-errors').addClass('hide').find('span').empty();
					$('#upload-success').addClass('hide');
				},
				uploadprogress(file, progress, bytes) {
					$(file.previewElement).css({ width:'100%', textAlign: 'center', margin: '2em 0', minHeight : 'auto' });
					$(file.previewElement).find('.progress-meter').css('width', progress + '%');
					if (progress == 100) {
						$('[data-dz-uploadprogress]').parent('div.progress').hide();
						$(file.previewElement).html($('#upload-file-spinner').html());

					}
				},
				canceled: function (msg) {

				},
				complete: function (file) {
					this.removeFile(file);
				},
				init: function () {
					this.on('error', function(file, message) {
						// If the user is unauthorised redirect to the dashboard
						if (file.xhr && [401, 403].includes(file.xhr.status)) {
							window.location.href = '/';
						}
						// If the file size is too large
						if (file.size > this.options.maxFilesize * 1024 * 1204) {
							$('#upload-error')
								.removeClass('hide')
								.html('File size is greater than ' + this.options.maxFilesize + 'MB');
						}
						var extn = file.name.match(/\.\w+$/);
						if (extn != null) {
							const accepted = this.options.acceptedFiles.split(',')
							if (accepted.length && false === accepted.includes(extn[0])) {
								$('#upload-error')
									.removeClass('hide')
									.html(`Please check the Accepted Formats. Files with the extension ${extn} are not allowed`);
							}
						}
						this.removeAllFiles();
					});
					this.on('success', function (file, data) {
						if (data) {
							if (data.error) {
								renderErrors(data);
							} else if (data.mapper) {
								renderMapper(data);
							} else {
								renderSuccess(data)
							}
						} else {
							// response is not valid
						}
						this.removeAllFiles();
					});

					$('#accepted-formats').text(this.options.acceptedFiles);
					$('#max-file-size').text(this.options.maxFilesize + 'MB');
				},
				dictDefaultMessage: i18n.__('dropzone.default'),
				dictFallbackMessage: i18n.__('dropzone.fallback'),
				dictFallbackText: i18n.__('dropzone.fallback.pre'),
				dictFileTooBig: i18n.__('dropzone.size'),
				dictInvalidFileType: i18n.__('dropzone.type'),
				dictResponseError: i18n.__('dropzone.response'),
				dictCancelUpload: i18n.__('dropzone.cancel'),
				dictUploadCanceled: i18n.__('dropzone.cancelled'),
				dictCancelUploadConfirmation: i18n.__('dropzone.cancel.confirm'),
				dictRemoveFile: i18n.__('dropzone.remove'),
				dictRemoveFileConfirmation: null,
				dictMaxFilesExceeded: i18n.__('dropzone.max'),
				dictFileSizeUnits: {
					tb: i18n.__('filesizes.tb'),
					gb: i18n.__('filesizes.gb'),
					mb: i18n.__('filesizes.mb'),
					kb: i18n.__('filesizes.kb'),
					b:  i18n.__('filesizes.b')
				}
			},
			config
		);

		return new Dropzone('#upload-file', options);

	// Dropzone has not been included, just return a jQuery object so any event hooks don't fail
	} else {
		return $('<span>');
	}
}

function renderErrors(data)
{
	if (typeof data.error == 'string') {
		data.error = {'-1' : [data.error]};
	}

	let $rowTemplate = $('#upload-row-error > div').clone();
	let $fieldTemplate = $('#upload-field-error > div').clone();
	let $parent = $('#import-errors').find('span');
	$parent.empty();

	$.each(data.error, function(line, errors) {

		let $row = $rowTemplate.clone();
		let $line = $row.find('.upload-error-line');
		let html = $line.text();

		if (line >= 0) {
			line = Number(line) + (data.response.header === false ? 1 : 2);
			html = html.replace('{line}', line);
		} else {
			html = 'File Error';
		}

		$line.text($.trim(html));
		let $span = $row.find('span');
		$.each(errors, function(column, value) {
			let display = column;
			if (data.response && data.response.header && data.response.header[column]) {
				display = data.response.header[column];
			}
			let name = (column < 0 ? '0' : display);
			let $field = $fieldTemplate.clone();
			let html = $field.html();
			html = html.replace('{column}', name);
			html = html.replace('{message}', value);
			$field.html($.trim(html));
			$span.append($field);
		});

		$parent.append($row);
	});

	$('#import-errors').removeClass('hide');
	$('#upload-error').removeClass('hide');

	$('#dropzone').removeClass('hide');
	$('#mapper').addClass('hide');
}

function renderSuccess(data)
{
	let html;
	let $complete = $('#upload-file-success > span').clone();
	let $success = $complete.find('.upload-success-success');
	let $existing = $complete.find('.upload-success-existing');
	let $span = $('#upload-success').find('span').eq(0);
	$complete.empty();
	$span.empty();

	html = $success.html();
	html = html.replace('{success}', data.response.success);
	$success.html($.trim(html));
	$complete.append($success);

	html = $existing.html();
	html = html.replace('{existing}', data.response.existing);
	$existing.html($.trim(html));
	$complete.append($existing);

	$span.append($complete);
	$('#upload-success').removeClass('hide');

	if (typeof data.refresh !== 'undefined') {
		window.setTimeout(
			() => { location.reload(); },
			2000
		);
	}

	$('#dropzone').removeClass('hide');
	$('#mapper').addClass('hide');
}

function renderMapper(data)
{
	$('#mapper').html(data.html);
	$('.sumo-select-delay').each(function () {
		$(this).SumoSelect();
	});
	const abide = new Foundation.Abide($('form[name="import-mapping"]'));
	const reveal = new Foundation.Reveal($('#import-sample-modal'));

	$('#dropzone').addClass('hide');
	$('#mapper').removeClass('hide');
	$('#mapper').data('response', data.data);
}

/**
 * Class to deal with imports using the column mapper process
 */
export const ImportMapper = {
	mapping: null,
};

/**
 * Creates an upload dropzone and initialises.
 *
 * @param  object config The Dropzoen config.
 * @return void
 */
ImportMapper.createDropzone = (config) => {
	ImportMapper.mapping = config.mapping;
	createImportDropzone(config);
	ImportMapper.ready();
};

/**
 * Posts the mapping information to the server.
 *
 * @param  Form form The form object.
 * @return void
 */
ImportMapper.saveMapping = (form) => {
	$('#setMapping').prop('disabled', true).addClass('disabled');
	const data = form.serializeArray();
	$.post({
		url: ImportMapper.mapping,
		data: data,
		dataType: 'json',
	}).done(data => {
		if (data.error) {
			renderErrors(data);
		} else {
			renderSuccess(data);
		}
	}).fail(jqxhr => {
		// console.log('fail');
	}).always(jqxhr => {
		$('#setMapping').prop('disabled', false).removeClass('disabled');
	});
};

/**
 * Creates event handlers for the import mapper.
 *
 * @return void
 */
ImportMapper.ready = () => {
	$(document)
		.on('click', '.import-example', function (e) {
			const response = $('#mapper').data('response');
			const data = $(this).data();
		})
		.on('change', 'select.import-column', function (e) {
			const index = $(this).val();
			const uuid = $(this).attr('name');
			if (index.length) {
				$(`[data-uuid="${uuid}"]`)
					.removeAttr('disabled')
					.prop('disabled', false)
					.data('index', index);

			} else {
				$(`[data-uuid="${uuid}"]`).prop('disabled', true);
			}
		})
		.on('invalid.zf.abide', function (e, field) {
			// console.log(field)
		})
		.on('valid.zf.abide', function (e, field) {
			// console.log(field)
		})
		.on('forminvalid.zf.abide', function (e, form) {
			// console.log(form.attr('name'), 'error');
		})
		.on('formvalid.zf.abide', function (e, form) {
			switch (form.attr('name')) {
				case 'import-mapping':
					ImportMapper.saveMapping(form);
					break;
			}
		});
};
