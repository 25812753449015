export function handleGeocode(response) {

	let address = 'Invalid';
	let addressObj = null;
	if (response.results[0]) {
		let routes = {};

		if (console) console.log(response);

		$.each(response.results, function(r, result) {
			$.each(result.address_components, function(c, component) {
				if (component.types.includes('route')) {

					if (routes[component.long_name] == undefined) {
						routes[component.long_name] = [];
					}
					routes[component.long_name].push(result);

				}
			});
		});

		if (Object.entries(routes).length) {
			let relevants = null;
			let max = 0;
			$.each(routes, function (r, route) {
				if (route.length > max) {
					max = route.length;
					relevants = route;
				}
			});

			if (relevants.length) {
				let parts;
				$.each(relevants, function(r, relevant) {
					parts = [];
					$.each(relevant.address_components, function(c, component) {
						if (component.types.includes('route') || component.types.includes('postal_town')) {
							parts.push(component.long_name);
							addressObj = relevant.address_components;
						}
						if (component.types.includes('postal_code') && !component.types.includes('postal_code_prefix')) {
							parts.push(component.long_name);
						}
					});
					if (parts.length > 2) {
						return false;
					}
				});

				address = parts.join(', ');
			}
		}

		if (addressObj) {
			let addresses = {
				addressLine1: [],
				addressLine2: null,
				addressLine3: null,
				addressLine4: null,
				district: null,
				city: null,
				county: null,
				postCode: null,
				country: null,
			}
			let componentMap = {
				'street_number' : 'addressLine1',
				'route' : 'addressLine1',
				'locality' : 'addressLine2',
				'postal_town' : 'city',
				'administrative_area_level_2' : 'county',
				'postal_code' : 'postCode',
				'country' : 'country',
			}

			$.each(addressObj, function(c, component) {
				let i = 1;
				$.each(componentMap, function(cname, afield) {
					if (component.types.includes(cname)) {
						if (afield == 'addressLine1') {
							if (cname == 'street_number') {
								addresses.addressLine1[0] = component.long_name;
							}
							if (cname == 'route') {
								if (addresses.addressLine1.length == 1) {
									addresses.addressLine1[1] = component.long_name;
								} else {
									addresses['addressLine' + (++i)] = component.long_name;
								}
							}
						} else {
							addresses[afield] = component.long_name;
						}
					}
				});
			});

			addresses.addressLine1 = addresses.addressLine1.join(' ');
			addressObj = addresses;
		}

	}
	return { address:address, object: addressObj };
}
