const filters = {
   status: {active: false, inactive: false},
   types: {permits: false, scratchcards: false, scpermits: false},
};

//  Whether the actions menu is active
var menuActive = false;
// Whether the booking has just been clicked
var bookingClicked = false;

/**
 *
 */
function initialiseControls()
{
    const hasLocalStorageFilters = [
        'MyBookings_filters_active',
        'MyBookings_filters_inactive',
        'MyBookings_filters_permits',
        'MyBookings_filters_scratchcards',
        'MyBookings_filters_scpermits'
    ].some(key => localStorage.getItem(key) !== null);

    const querystring = new URLSearchParams(window.location.search);
    // If no localStorage filters, fetch defaults from database
    if (!hasLocalStorageFilters) {
        $.ajax({
            url: '/applicant/bookingsDefaultFilters',
            dataType: 'json',
            method: 'GET',
            success: (defaultFilters) => {
                // Set filters to the defaults from database
                const filters = {
                    active: defaultFilters.active,
                    inactive: defaultFilters.inactive,
                    permits: defaultFilters.permits,
                    scratchcards: defaultFilters.scratchcards,
                    scpermits: defaultFilters.scpermits,
                };
                applyFilters(filters);
            }
        });
    } else {
        const filters = {
            active: querystring.get('active') || localStorage.getItem('MyBookings_filters_active'),
            inactive: querystring.get('inactive') || localStorage.getItem('MyBookings_filters_inactive'),
            permits: querystring.get('permits') || localStorage.getItem('MyBookings_filters_permits'),
            scratchcards: querystring.get('scratchcards') || localStorage.getItem('MyBookings_filters_scratchcards'),
            scpermits: querystring.get('scpermits') || localStorage.getItem('MyBookings_filters_scpermits'),
        }
        applyFilters(filters)
    }

    // Sort
    const order = querystring.get('order') || localStorage.getItem('MyBookings_sort_order') || 'created|1';
    $('select[name="my-bookings-sort"]').val(order).change();
    localStorage.setItem('MyBookings_sort_order', order);

    $('#bookings').show();
}

function applyFilters(filters) {
    for (const [key, value] of Object.entries(filters)) {
        const checked = value == 1;
        $(`input[name="${key}-filter"]`).prop('checked', checked);
        if (checked) {
            toggleFilter(key, checked);
        }
    }
}

/**
 *
 */
function toggleFilter(key = null, checked = false)
{
    if (['active', 'inactive'].includes(key)) {
        filters.status[key] = checked;
    } else if (['permits', 'scratchcards', 'scpermits'].includes(key)) {
        filters.types[key] = checked;
    }

    showOrHide();
}

/**
 *
 */
function showOrHide()
{
    var $bookings = $('div.booking');
    $bookings.hide();

    for (const [name, options] of Object.entries(filters)) {
        const checked = Object.values(options).filter(v => v);
        if (checked.length && checked.length != Object.values(filters.types).length) {
            const attributes = [];
            for (const [filter, value] of Object.entries(options)) {
                if (value) {
                    attributes.push(`[data-${filter}]`)
                    localStorage.setItem(`MyBookings_filters_${filter}`, 1);
                } else {
                    localStorage.removeItem(`MyBookings_filters_${filter}`);
                }
            }
            $bookings = $bookings.filter(attributes.join(','));
        } else {
            for (const [filter, value] of Object.entries(options)) {
                localStorage.removeItem(`MyBookings_filters_${filter}`);
            }
        }
    }

    $bookings.show();
}

$(document).ready(function () {

    if ($('#my-bookings').length == 0) {
        return;
    }

    $('body')
        // Filter Checkbox
        .on('click', 'input.filter', function() {
            var key = $(this).attr('name').replace('-filter', '');
            const checked = $(this).is(':checked');
            toggleFilter(key, checked);
        })
        // Sort Dropdown
        .on('change', 'select[name="my-bookings-sort"]', function() {
            const value = $(this).val();
            localStorage.setItem('MyBookings_sort_order', value);
            const [order, direction] = value.split('|');
            const offset = [[-1, 1], [1, -1]];

            var $bookings = $('div.booking');
            const bookings = [];
            $bookings.each(function() {
                bookings.push({id: $(this).attr('id'), order: $(this).data(order)})
            });
            bookings.sort((a, b) => {
                if (a.order == b.order) {
                    return 0;
                }
                return a.order < b.order ? offset[direction][0] : offset[direction][1];
            });

            const $div = $('<div></div>');
            bookings.forEach(booking => {
                $('#bookings').append($(`#${booking.id}`))
            });

            $('#bookings').append($div.html());

            const $context = $('span[data-context]');
            $context.hide();
            $context.filter(`[data-${order}]`).show();

            $('#bookings').foundation();
        })
        .on('redraw.bookings', function() {
            showOrHide();
            $('select[name="my-bookings-sort"]').change();
        })
        .on('click tap', 'div.booking', function(e) {
            // Don't fire if the actions menu is active
            if (menuActive) {
                return;
            }
            // We can't stop the reveal click propagation otherwise foundation won't show the reveal
            // So we have to stop this click re-firing like this
            if (bookingClicked) {
                return;
            }

            bookingClicked = true;
            const parts = $(this).attr('id').split('-');
            const url = `/app/view-booking/${parts[0]}/${parts[1]}`;
            document.location = url;
        })
        .on('mouseenter', 'div.booking', function() {
            $(this).css('cursor', 'pointer');
        })
        .on('mouseleave', 'div.booking', function() {
            $(this).css('cursor', 'auto');
        })
        .on('click tap', 'button.actions-menu-link', function(e) {
            menuActive = true;
        })
        .on('show.zf.dropdown', function(e, el) {
            if ($(el).hasClass('actions-menu')) {
                menuActive = true;
            }
        })
        .on('hide.zf.dropdown', function(e, el) {
            if ($(el).hasClass('actions-menu')) {
                menuActive = false;
            }
        })
        .on('open.zf.reveal', function(e) {
            bookingClicked = false;
        });

    // Load control state from querystring or localStorage
    initialiseControls();
});
