import i18n from '../../utilities/i18n.js';

const filters = {
   status: {active: false, inactive: false},
   types: {
        car: false,
        van: false,
        lgv: false,
        coach: false,
        trailer: false,
        motorcycle: false,
        boat: false,
        other: false,
   },
};

//  Whether the actions menu is active
var menuActive = false;
// Whether the vehicle has just been clicked
var vehicleClicked = false;

/**
 *
 */
function initialiseControls()
{
    // Filters
    const querystring = new URLSearchParams(window.location.search);
    const filters = {
        car: querystring.get('car') || localStorage.getItem('MyVehicles_filters_car'),
        van: querystring.get('van') || localStorage.getItem('MyVehicles_filters_van'),
        lgv: querystring.get('lgv') || localStorage.getItem('MyVehicles_filters_lgv'),
        coach: querystring.get('coach') || localStorage.getItem('MyVehicles_filters_coach'),
        trailer: querystring.get('trailer') || localStorage.getItem('MyVehicles_filters_trailer'),
        motorcycle: querystring.get('motorcycle') || localStorage.getItem('MyVehicles_filters_motorcycle'),
        boat: querystring.get('boat') || localStorage.getItem('MyVehicles_filters_boat'),
        other: querystring.get('other') || localStorage.getItem('MyVehicles_filters_other'),
    }
    for (const [key, value] of Object.entries(filters)) {
        const checked = value == 1;
        $(`input[name="${key}-filter"]`).prop('checked', checked);
        if (checked) {
            toggleFilter(key, checked);
        }
    }

    // Sort
    const order = querystring.get('order') || localStorage.getItem('MyVehicles_sort_order') || 'created|1';
    $('select[name="my-vehicles-sort"]').val(order).change();
    localStorage.setItem('MyVehicles_sort_order', order);

    $('#vehicle-list').show();
}

/**
 *
 */
function toggleFilter(key = null, checked = false)
{
    if (['active', 'inactive'].includes(key)) {
        filters.status[key] = checked;
    } else if (['car', 'van', 'lgv', 'coach', 'trailer', 'motorcycle', 'boat', 'other'].includes(key)) {
        filters.types[key] = checked;
    }

    showOrHide();
}

/**
 *
 */
function showOrHide()
{
    var $vehicles = $('div.vehicle-item');
    $vehicles.hide();

    for (const [name, options] of Object.entries(filters)) {
        const checked = Object.values(options).filter(v => v);
        if (checked.length && checked.length != Object.values(filters.types).length) {
            const attributes = [];
            for (const [filter, value] of Object.entries(options)) {
                if (value) {
                    attributes.push(`[data-${filter}]`)
                    localStorage.setItem(`MyVehicles_filters_${filter}`, 1);
                } else {
                    localStorage.removeItem(`MyVehicles_filters_${filter}`);
                }
            }
            $vehicles = $vehicles.filter(attributes.join(','));
        } else {
            for (const [filter, value] of Object.entries(options)) {
                localStorage.removeItem(`MyVehicles_filters_${filter}`);
            }
        }
    }

    $vehicles.show();
}

$(document).ready(function () {

    if ($('#vehicle-list').length == 0) {
        return;
    }

    $('body')
        // Filter Checkbox
        .on('click', 'input.filter', function() {
            var key = $(this).attr('name').replace('-filter', '');
            const checked = $(this).is(':checked');
            toggleFilter(key, checked);
        })
        // Sort Dropdown
        .on('change', 'select[name="my-vehicles-sort"]', function() {
            const value = $(this).val();
            localStorage.setItem('MyVehicles_sort_order', value);
            const [order, direction] = value.split('|');
            const offset = [[-1, 1], [1, -1]];

            var $vehicles = $('div.vehicle-item');
            const vehicles = [];
            $vehicles.each(function() {
                vehicles.push({id: $(this).attr('id'), order: $(this).data(order)})
            });
            vehicles.sort((a, b) => {
                if (a.order == b.order) {
                    return 0;
                }
                return a.order < b.order ? offset[direction][0] : offset[direction][1];
            });

            const $div = $('<div></div>');
            vehicles.forEach(vehicle => {
                $('#vehicle-list').append($(`#${vehicle.id}`))
            });

            $('#vehicle-list').append($div.html());

            $('#vehicle-list').foundation();
        })
        .on('redraw.vehicles', function() {
            showOrHide();
            $('select[name="my-vehicles-sort"]').change();
        })
        .on('click tap', 'div.vehicle-item', function(e) {
            // Don't fire if the actions menu is active
            if (menuActive) {
                return;
            }
            // We can't stop the reveal click propagation otherwise foundation won't show the reveal
            // So we have to stop this click re-firing like this
            if (vehicleClicked) {
                return;
            }

            vehicleClicked = true;
            // const id = $(this).data('id');
            // const url = `/app/view-vehicle/${id}`;
            // document.location = url;

            /**
             * COPIED FROM APPLICANT.JS TEMPORARILY WHILE WE ARE STILL USING MODALS
             * SEE ZPRM-5287
             */
            $('#reveal-add-vehicle')
                .find('button').prop('disabled', true)
                .end()
                .find('h1').text(i18n.__('portal.myvehicles.edit'));
            $('input[name=vehicleName]').prop('disabled', true);
            $('select[name=vehicleType]').prop('disabled', true);
            $('input.vrm').prop('disabled', true);
            $('select[name=vehicleMake]').prop('disabled', true);
            $('input[name=vehicleModel]').prop('disabled', true);
            $('select[name=vehicleColour]').prop('disabled', true);
            $.post({
                url: '/applicant/getVehicleSummary',
                data: { vehicleId : $(this).data('id'), raw : true },
                dataType: 'json',
            }).done(function(data) {
                if (data === false) {
                    $('#errors').html(i18n.__('errors.failed')).show();
                    return;
                }
                $('[data-vehicleId]').val(data.id);
                if (data.name == undefined) {
                    data.name = { 'value' : '' };
                }
                $('input[name=vehicleName]').prop('disabled', false).val(data.name.value);
                $('select[name=vehicleType]').prop('disabled', true).val(data.type)[0].sumo.reload();
                $('input.vrm').prop('disabled', true).val(data.vrm);
                $('select[name=vehicleMake]').prop('disabled', false).val(data.make)[0].sumo.reload();
                $('input[name=vehicleModel]').prop('disabled', false).val(data.model);
                $('select[name=vehicleColour]').prop('disabled', false).val(data.colour)[0].sumo.reload();
                $('#reveal-add-vehicle').find('button').prop('disabled', false);
                $('#reveal-add-vehicle').foundation('open');
            });
        })
        .on('mouseenter', 'div.vehicle-item', function() {
            $(this).css('cursor', 'pointer');
        })
        .on('mouseleave', 'div.vehicle-item', function() {
            $(this).css('cursor', 'auto');
        })
        .on('click tap', 'button.actions-menu-link', function(e) {
            menuActive = true;
        })
        .on('click tap', '.booking-link', function(e) {
            vehicleClicked = true;
            e.stopPropagation();
        })
        .on('show.zf.dropdown', function(e, el) {
            if ($(el).hasClass('actions-menu')) {
                menuActive = true;
            }
        })
        .on('hide.zf.dropdown', function(e, el) {
            if ($(el).hasClass('actions-menu')) {
                menuActive = false;
            }
        })
        .on('open.zf.reveal', function(e) {
            vehicleClicked = false;
        });

    // Load control state from querystring or localStorage
    initialiseControls();
});

