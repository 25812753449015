const i18n = { keys: null };

/**
 * Replaces a translation key with the text for the locale.
 * @see    trans
 *
 * @param  string      key     The translation key.
 * @param  object|null replace An object of parameters to replace in the string.
 * @return string The value of the key for the user's locale.
 */
i18n.__ = (key, replace = {}) => {
	return i18n.trans(key, replace);
}

/**
 * Replaces a translation key with the text for the locale.
 * @see    __
 *
 * @param  string      key     The translation key.
 * @param  object|null replace An object of parameters to replace in the string.
 * @return string The value of the key for the user's locale.
 */
i18n.trans = (key, replace = {}) => {
	if (null === key || '' === key) {
		return '';
	}
	let message = i18n.get(key);
	message = i18n.trans_params(message, replace);
	return message;
}

/**
 * Replaces a translation key with the text for the locale using pluralisation.
 * @see trans_choice
 *
 * @param  string         key     The translation key.
 * @param  integer|object number  The amount to use when pluralising.
 * @param  object|null    replace An object of parameters to replace in the string.
 * @return string The value of the key for the user's locale.
 */
i18n.__n = (key, number, replace = {}) => {
	return i18n.trans_choice(key, number, replace);
}

/**
 * Replaces a translation key with the text for the locale using pluralisation.
 * @see __n
 *
 * @param  string         key     The translation key.
 * @param  integer|object number  The amount to use when pluralising.
 * @param  object|null    replace An object of parameters to replace in the string.
 * @return string The value of the key for the user's locale.
 */
i18n.trans_choice = (key, number, replace = {}) => {
	if (null === key || '' === key) {
		return '';
	}
	if (typeof number == 'object') {
		number = Object.keys(number).length;
	}
	let message = i18n.get(key);
	let messages = message.split('|');
	if (messages.length > 1) {
		let found = false;
		let matches;
		for (message of messages) {
			matches = message.match(/^({(\d+)}|\[(\d+),(\*|\d+)\])\s*/);
			if (matches == null) {
				continue;
			}
			if (matches[3]) {
				if (
					number >= matches[3]
					&& (matches[4] == '*' || number <= matches[4])
				) {
					found = true;
					break;
				}
			} else if (number == matches[2]) {
				found = true;
				break;
			}
		}
		if (found) {
			message = message.replace(matches[0], '');
		} else {
			message = number == 1 ? messages[0] : messages[1];
		}
	}
	replace.count = number;
	message = i18n.trans_params(message, replace);
	return message;
}

/**
 * Replaces parameters in a message with their values.
 *
 * @param  string message The translated message containing parameters.
 * @param  object replace An array of parameter keys and values.
 * @return string The message with parameters replaced.
 */
i18n.trans_params = (message, replace) => {
	let keys = Object.keys(replace);
	if (keys.length == 0) {
		return message;
	}

	// Ensure we replace :foobar before we replace :foo
	keys.sort((a, b) => {
		let lengths = [a.length, b.length];
		return lengths[0] == lengths[1] ? 0 : (lengths[0] > lengths[1] ? -1 : 1);
	});

	// Replace our parameters
	keys.forEach((key) => {
		replace[key] = replace[key] ? replace[key].toString() : '';
		let camel = replace[key].length ? replace[key][0].toUpperCase() + replace[key].substring(1) : '';
		message = message
			.replace(
				`:${key}`,
				replace[key]
			)
			.replace(
				`:${key}`.toUpperCase(),
				replace[key].toUpperCase()
			)
			.replace(
				':' + key[0].toUpperCase() + key.substring(1),
				camel
			);
	});

	return message;
}

/**
 * Returns the locale set for this page.
 *
 * @return string
 */
i18n.locale = () => {
	return $('html').attr('lang').replace('-', '_');
}

/**
 * Returns the message for the given key.
 *
 * @param  string key    The key to look for.
 * @param  string locale The locale to look for.
 * @return string
 */
i18n.get = (key) => {
	if (i18n.keys == null) {
		// i18n.keys = JSON.parse($('#i18n-keys')[0].textContent);
		i18n.keys = JSON.parse(document.getElementById('i18n-keys').textContent);
	}
	return i18n.keys[key] ? i18n.keys[key] : (PRODUCTION ? '' : key);
}

export default i18n;
